<template>
  <div class="auth-wrapper auth-v1 pa-0 white">
    <div class="auth-inner">
      <v-card class="auth-card" elevation="0">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7 pb-2">
          <v-avatar
            color="primary"
            size="100"
            class="pa-3"
            >
            <v-img
              :src="require('@/assets/images/logos/logo-primary-white.png')"
              width="100px"
              alt="logo"
              contain
            ></v-img>
          </v-avatar>
        </v-card-title>

        <!-- title -->
        <v-card-text class="text-center black--text">
          <p class="headline font-weight-bold mb-2">
            Register to VDC
          </p>
          <p class="mb-2">
            Daftar untuk memulai campaign
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="business_name"
              outlined
              rounded
              :rules="[v => !!v || 'Bisnis belum diisi']"
              label="Nama Bisnis"
              placeholder="VDC Restaurant"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="name"
              outlined
              rounded
              :rules="[v => !!v || 'Nama belum diisi']"
              label="Nama Anda"
              placeholder="John"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="whatsapp"
              outlined
              rounded
              :rules="[v => !!v || 'WhatsApp belum diisi']"
              label="Nomor WhatsApp Anda"
              placeholder="081xxxxxxx"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="email"
              outlined
              rounded
              :rules="emailRules"
              label="Email Anda"
              placeholder="vdc@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>
            <v-textarea
              outlined
              rounded
              class="pb-0 mb-0"
              height="100"
              :rules="[v => !!v || '']"
              v-model="message"
              label="Pesan"
              placeholder="Kebutuhan Influencer Marketing Bisnis Anda"
            ></v-textarea>

            <v-checkbox
              hide-details
              class="mt-0 pt-0"
              aria-required="true"
              :rules="[v => !!v || '']"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I Agree to</span><a @click="goToTerm()">Term &amp; Conditions</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-2 pt-0 text-capitalize"
              @click="validate()"
              :disabled="isLoading"
              rounded
              x-large
            >
              Register
            </v-btn>
            <v-btn
              block
              outlined
              color="primary"
              class="mt-3 text-capitalize"
              @click="goToHome()"
              rounded
              x-large
            >
              Kembali ke Beranda
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-0">
          <span class="me-2">
            Sudah punya akun?
          </span>
          <router-link :to="{ name:'login' }">
            Login Sekarang
          </router-link>
        </v-card-text>
        <v-card-text>
          <div class="d-flex justify-center mt-0 pt-0">
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-alert v-if="alertStatus" :type="alertType" class="full-width round">
              {{alertMessage}} <button style="float:right" type="button" v-on:click="closeAlert" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </v-alert>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  setup() {
    return {
      business_name:'',
      name:'',
      whatsapp:'',
      email:'',
      message:'',
      term:false,
      valid:true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      isLoading:false,
      alertStatus:false,
      alertType:'error',
      alertMessage:'Loading . . .',
    }
  },
  methods:{
    validate () {
      let checkValid = this.$refs.form.validate()
      if(checkValid){
        this.submit();
      }
    },
    submit () {
      this.isLoading = true;
      axios.post(
          process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/createCampaign', { business_name: this.business_name, pic_name: this.name, email: this.email, whatsapp: this.whatsapp, message: this.message }
        ).then(response => {
          if(response.status === 201){
            setTimeout(() => { 
              this.alertType='success';
              this.alertMessage = 'Pendaftaran Berhasil! Kami akan menghubungi Anda :)';
              this.isLoading = false; 
              this.alertStatus=true;
              this.resetForm();
            }, 2000);
            setTimeout(() => { 
              // this.$router.push({name:'login'});
            }, 3000);
          }
        }).catch(error => {
          setTimeout(() => { 
            this.isLoading = false; 
            this.alertType='error';
            this.alertMessage='Error! Terjadi Kesalahan';
            this.alertStatus=true;
            this.resetForm();
          }, 2000);
      });
    },
    closeAlert(){
      this.alertStatus = false;
    },
    goToTerm(){
      window.open('https://vicedigitalcreative.com/terms_conditions');
      // this.$router.push({name:'register'});
    },
    goToHome(){
      this.$router.push({name:'home'});
    },
    resetForm(){
      this.business_name = this.name = this.whatsapp = this.message = ' ';
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
