<template>
  <v-row no-gutters>
    <v-col cols="12">
      <register-page></register-page>
    </v-col>
  </v-row>
</template>

<script>
import RegisterPage from './components/PageRegister.vue'

export default {
  name: 'register',
  metaInfo: {
    title: 'Register'
  },
  components: {
    RegisterPage,
  },
  setup() {
    return {}
  },
  mounted(){
    // if(localStorage.getItem('token')){
      // this.$router.push({name:'home'});
    // }
  },
}
</script>
